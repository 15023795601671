.max-width-160{
    max-width: 160px;
}
label{
    margin-bottom: 0;
}
.react-dropdown-tree-select {
    display: inline-flex;
    width: inherit;
}
.react-dropdown-tree-select .dropdown {
    width: inherit;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.75rem;
}
.react-dropdown-tree-select .dropdown .dropdown-trigger {
    border: 0.01rem solid #dadbde;
    border-radius: 0.1875rem;
    width: inherit;
}
.react-dropdown-tree-select .dropdown .dropdown-trigger:hover {
    border: 0.01rem solid #000000;
}
.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.disabled:hover {
    border: 0.01rem solid #dadbde;
}
.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.disabled {
    color: #dadbde;
}

.departmenttitledd a {
    min-height: 48px;
    padding-top: 9px;
}

.react-dropdown-tree-select .dropdown .dropdown-content .search {
    padding: 0.5rem 0 0.6rem 0.75rem;
    position: sticky;
    top: 0;
    background-color: #ffffff;
    border-bottom: 0.01rem solid #dadbde;
}

.react-dropdown-tree-select .dropdown .dropdown-content ul {
    padding-left: 16px;
}

.tag-item .search {
    border-bottom: none;
    cursor: inherit;
    font-family: inherit;
    font-size: 0.85rem;
    padding: 0;
}
.tag-item {
    margin: 0.2em 0.2em 0.2em 0;
    display: inline-block;
}
.tag-item .placeholder {
    font-size: 0.85rem;
    margin: 0;
}
.tag {
    background-color: transparent;
    font-size: 0.85rem;
    border-radius: 2px;
    color: #000000;
    padding: 0.1875rem 0.5625rem;
}

.tag-remove {
    color: #000000;
    font-size: 100%;
    cursor: pointer;
}

.node.match-in-children.hide .node-label {
    opacity: 1;
    color: #000000;
}

.dropdown-content {
    min-width: 10rem;
    padding: 0 !important;
    border: 0.01rem solid #dadbde !important;
    border-radius: 0.25rem;
    height: 200px;
    overflow: auto;
    z-index: 1001 !important;
    text-align: left;
    box-shadow: none !important;
}

.departmenttitledd .dropdown-content {
    height: 400px;
}

.sortbydd .dropdown-content {
    height: 350px;
    width: 200px !important;
}

.sortbydd .dropdown-content .root {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
}

.groupbydd .dropdown-content {
    height: 210px;
    width: 180px !important;
}

.groupbydd .dropdown-content .root {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
}

.dropdown-content:hover {
    border: 0.01rem solid #000000 !important;
}
.dropdown-content .search {
    font-size: 0.85rem;
}
.node {
    padding: 0.4rem 0;
    height: 36px;
}
.node:hover {
    background-color: #f5f6f8;
}
.node > label {
    margin-bottom: 0;
    padding-left: 8px;
    font-size: 0.85rem;
    width: 100%;
}
.node.disabled > * {
    color: #000000;
    cursor: default;
}
.react-dropdown-tree-select input[type="checkbox" i]:disabled {
    display: none;
}
.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.bottom:after {
    content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iNiIgdmlld0JveD0iMCAwIDEwIDYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xIDFMNSA1TDkgMSIgc3Ryb2tlPSJibGFjayIvPgo8L3N2Zz4=");
    float: right;
    margin-left: 0.5625rem;
}
.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.top:after {
    content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iNiIgdmlld0JveD0iMCAwIDEwIDYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xIDFMNSA1TDkgMSIgc3Ryb2tlPSJibGFjayIvPgo8L3N2Zz4=");
    float: right;
    margin-left: 0.5625rem;
}
.infinite-scroll-component {
    overflow-x: hidden !important;
}

/* custom classes */
.dropdown-custom-nav {
    vertical-align: top;
}
.dropdown-custom-nav.modal {
    width: 10rem;
}
.small,
small {
    padding: 0 0.5625rem;
}
.dropdown-custom-nav.small {
    /* width: 12rem; */
    width: fit-content;
    display: flex;
    flex-direction: row;
}
.dropdown-custom-nav.small .dropdown-content {
    width: 10rem;
    left: 2rem;
    top: 2.15rem;
}
.dropdown-custom-nav.medium {
    width: 14rem;
}
.dropdown-custom-nav.medium .dropdown-content {
    width: 10rem;
    left: 4rem;
    top: 2.15rem;
}
.dropdown-custom-nav.disable-search .search {
    display: none;
}
.dropdown-custom-nav.required .tag-remove {
    display: none;
}
.dropdown-custom-nav input {
    max-width: 11rem;
}
.dropdown-custom-nav .dropdown-trigger {
    border: none !important;
    padding: 0 !important;
}
.dropdown-custom-nav .tag-list {
    display: inline-flex;
}
.dropdown-custom-nav .tag {
    border: none;
    padding: 0;
    margin: 0;
}
.dropdown-custom-nav .tag-item {
    margin: 0;
}
.dropdown-custom-nav .tag-item:not(:first-child) {
    display: none;
}
.dropdown-custom-nav:hover {
    background-color: #edeff2;
    border-radius: 0.25rem;
}
button.dropdown-custom-search-close-icon {
    padding: 0 0.3rem;
    position: absolute;
    top: 0.5rem;
    right: 0.9rem;
    z-index: 9999;
    line-height: 1.3rem;
}

/* disabled */
.disabled .dropdown-trigger:hover {
    border: 0.01rem solid #dadbde !important;
}
.disabled input::placeholder {
    color: #e5e8ec;
}

/* modal */
.dropdown-custom-menu {
    width: 12rem;
}
.dropdown-custom-menu .tag-item:not(:first-child) {
    display: none;
}
.dropdown-custom-menu .tag {
    border: none;
    padding: 0;
}
.dropdown-custom-content {
    width: 100%;
}
.dropdown-custom-content .dropdown-content {
    width: 100%;
    max-height: 60vh;
    top: 0;
}
.dropdown-custom-content .dropdown-trigger {
    padding: 0;
}
.dropdown-custom-content .toggle {
    display: none;
}

.dropdown-custom-content .tag {
    background-color: #ededf2;
    margin: 0.25rem 0 0 0.5rem;
}

.dropdown-custom-content .node-label {
    width: 60vw;
    display: inline-block;
    padding-left: 8px;
}
.dropdown-custom-content .tag-item:last-child {
    display: block;
    position: absolute;
    top: 0.1rem;
    height: 2rem;
    padding: 0.4rem 0 0 0.5rem;
    margin: 0;
    background-color: #ffffff;
    width: calc(100% - 2rem);
}
.dropdown-custom-content .tag-list {
    display: inline-block;
    margin: 1.5rem 0 0 0;
    width: calc(100% - 2rem);
}
.dropdown-custom-nav .toggle {
    display: none;
}

li.node.focused {
    background-color: transparent;
}

li.node.checked {
    background-color: #f5f6f8;
}

li.node.focused:hover {
    background-color: #f5f6f8;
}

.tag-item .tag {
    font-size: 0.85rem;
    font-weight: 400;
    cursor: pointer;
}

.tag .tag-remove {
    background: transparent;
    width: 2rem;
    cursor: default;
    font-weight: bold;
}

.no-matches {
    padding: 0.6rem 0 0.6rem 0.75rem;
    font-size: 0.85rem;
    color: #5f636d;
}

.locationItem {
    line-height: 36px;
}

.locationGroup {
    line-height: 36px;
}

/* client and projects */
.dropdown-custom-content--ClientsAndProjects .tag {
    border: none;
    margin: 0;
    background-color: transparent;
    padding-top: 0.05rem;
    padding-bottom: 0.05rem;
}
.dropdown-custom-content--ClientsAndProjects .tag-item:last-child {
    display: none;
}
.dropdown-custom-content--ClientsAndProjects .tag-list {
    margin: 0;
}
