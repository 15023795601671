.planner {
    font-size: 10px;
}

.plannerHeader {
    font-size: 10px;
}

.plannerHeader > .p-button {
    font-size: 10px;
}

.p-datatable-header {
    font-size: 10px !important;
}

.p-multiselect .p-multiselect-label {
    font-size: 10px !important;
}

.p-inputtext {
    font-size: 10px !important;
}

.p-button-label {
    font-size: 10px !important;
}

.p-button {
    color: gray !important;
} 

.p-multiselect-items {
    color: black !important;
    font-size: 10px !important;
}

.p-datatable .p-datatable-tbody > tr {
    
}

.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
    padding: 1px;
}

td.baselineHrs {
    background-color: rgb(255, 228, 178) !important;
    text-align: right !important;
}

td.baselineFee {
    background-color: rgb(240, 240, 240) !important;
    text-align: right !important;
}

td.eacHrs {
    background-color: rgb(240, 240, 240) !important;
    text-align: right !important;
}

td.eacCost {
    background-color: rgb(240, 240, 240) !important;
    text-align: right !important;
}

td.varianceHrs {
    background-color: rgb(240, 240, 240) !important;
    text-align: right !important;
}

td.varianceCost {
    background-color: rgb(240, 240, 240) !important;
    text-align: right !important;
}

td.ratecard {
    background-color: rgb(240, 240, 240) !important;
    text-align: right !important;
}

td.rate {
    background-color: rgb(240, 240, 240) !important;
    text-align: right !important;
}

.forecastPast {
    width: 100px;
}

.forecastPastValue {
    padding-left: 10px;
    width: 50px;
    text-align: right;
    display: inline-block;
    background-color: rgb(248, 248, 248);
}

.actualPastValue {
    padding-left: 10px;
    width: 50px;
    display: inline-block;
    text-align: right;
}

.numericInput > input {
    width: 100%;
    text-align: right;
    height: 20px;
    padding: 5px;
    border-radius: 0px;
}

.forecastValue {
    padding-left: 10px;
    width: 100px;
    text-align: right;
    display: inline-block;
}

.p-rowgroup-header {
    background-color: rgb(102, 102, 102) !important;
    color: white !important;
    height: 10px !important;
}

.p-datatable .p-datatable-tbody > tr > td .p-row-toggler {
    width: 10px;
    height: 10px;
    color: white;
}

.p-multiselect .p-multiselect-label {
    padding: 5px !important;
}

.p-button.p-button-icon-only {
    width: 30px;
    padding: 5px;
    border: 1px solid gray;
}

.endDateCalendar > input {
    padding: 5px !important;
    border: 0px;
    width: 70px !important;
    font-size: 11px !important;
    border-radius: 0px !important;
}

.endDateCalendar > input:hover {
    border-bottom: 1px solid black !important;
}

.myprojectsDDL {
    padding: 0px !important;
    min-height: 25px !important;
    padding: 5px 5px 5px 5px !important;
    font-size: 11px !important;
}

.myprojectsDDL > div > div{
    font-size: 11px !important;
    line-height: 10px;
}

.p-dropdown {
    font-size: 11px !important;
    border-radius: 0px !important;
}

.p-dropdown > .p-inputtext {
    padding: 0px !important;
}