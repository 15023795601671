.slide-pane__header {
    display: flex;
    flex: 0 0 3rem;
    align-items: center;
    background-color: inherit;
    height: auto;
    border-bottom: none;
}

.slide-pane__close {
    right: 1rem;
    padding: 0;
    opacity: 1;
    position: absolute;
}

.slide-pane__content {
    padding: 0 0 2.5em 0;
    overflow-y: unset;
}

.slide-pane__title-wrapper {
    margin-left: 0rem;
    padding-top: 0.8rem;
}

.no-overlay {
    left: inherit;
    width: 32%;
}

.no-overlay.slide-pane__overlay.overlay-after-open {
    background-color: transparent;
    z-index: 999;
}

.sliding-pane-custom {
    box-shadow: -15px 0px 50px 0px rgba(0, 0, 0, 0.12);
}

.slide-pane__close svg {
    width: 16px;
}
